import {inject} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {NewInstance} from 'aurelia-dependency-injection';
import {ValidationRules, ValidationController} from 'aurelia-validation';
import {BootstrapFormValidationRenderer} from 'validation/bootstrap-form-validation-renderer';
import {I18n} from 'common/i18n';
import {Notifier} from 'common/ui';
import {RapidStarts} from 'services/rapid-starts';
import {c} from 'common/common';

@inject(DialogController, NewInstance.of(ValidationController), I18n, Notifier, RapidStarts)
export class RapidStartTopicEditor {

    id = null;
    rapidStartId = null;
    slug = '';
    ordering = null;
    isActive = false;
    showDeleteConfirm = false;
    canEditSlug = false;

    constructor(dialogController, validationController, i18n, notifier, rapidStarts) {
        this.dialogController = dialogController;
        this.dialogController.settings.centerHorizontalOnly = true;
        this.validationController = validationController;
        this.validationController.addRenderer(new BootstrapFormValidationRenderer());
        this.i18n = i18n;
        this.notifier = notifier;
        this.rapidStarts = rapidStarts;

        this.deleteText = this.i18n.tr('delete');

        ValidationRules
            .ensure('slug').required().matches(c.RegEx.slug)
            .ensure('ordering').required().satisfiesRule('integerRange', 1, 5000)
            .on(this);
    }

    activate(model) {
        this.id = null;
        this.rapidStartId = model.rapidStartId;
        this.slug = '';
        this.ordering = model.ordering || 1;
        this.isActive = false;
        this.title = this.i18n.tr('rapid-start-topic-add-title');
        if (model.topic) {
            this.title = this.i18n.tr('rapid-start-topic-edit-title');
            this.id = model.topic.id;
            this.slug = model.topic.slug;
            this.ordering = model.topic.ordering;
            this.isActive = model.topic.isActive;
        }

        this.canEditSlug = !this.id;
        this.validationController.reset();
    }

    save() {
        var me = this;
        this.validationController.validate().then(v => {
            if (!v.valid) return;

            me.rapidStarts.saveRapidStartTopic(me.id, me.rapidStartId, me.slug, me.ordering, me.isActive).then(() => {
                me.dialogController.ok({ rapidStartTopicDeleted: false });
            }).catch(error => { });
        });
    }

    resetDelete() {
        this.showDeleteConfirm = false;
        this.deleteText = this.i18n.tr('delete');
    }

    deleteRapidStartTopic() {
        if (!this.showDeleteConfirm) {
            this.showDeleteConfirm = true;
            this.deleteText = this.i18n.tr('delete-confirm');
            return;
        }

        this.showDeleteConfirm = false;
        var me = this;

        me.rapidStarts.deleteRapidStartTopic(me.id).then(() => {
            me.dialogController.ok({ rapidStartTopicDeleted: true });
        }).catch(error => { });
    }
}
