import { bindable } from 'aurelia-framework';
import { Notifier } from 'common/ui';
import { AgentSites, AGENT_SITE_TYPE } from 'services/agent-sites';
import copy from 'copy-to-clipboard';

export class MyBizCardOnline {
    static inject = [Notifier, AgentSites];
    _notifier;
    _agentSites;

    @bindable memberId;

    constructor(notifier, agentSites) {
        this._notifier = notifier;
        this._agentSites = agentSites;
    }

    async attached() {
        this._isAttached = true;
        await this._load();
    }

    memberIdChanged() {
        if (!this._isAttached) return;
        this._load();
    }

    async _load() {
        if (!this.memberId) return;
        try {
            this.status = await this._agentSites.status(AGENT_SITE_TYPE.MyBizCardOnline, this.memberId);
        } catch (err) {
            console.log(err);
        }
    }

    async createMyBizCard() {
        try {
            this.creating = true;
            this.status = await this._agentSites.createExternal(AGENT_SITE_TYPE.MyBizCardOnline, this.memberId);
        } catch (err) {
            console.log(err);
            this._notifier.error(err);
        } finally {
            this.creating = false;
        }
    }

    copyToClipboard() {
	    copy(this.status.url);
        this._notifier.success('mbco-url-copy-success');
    }
}