import { PLATFORM } from 'aurelia-pal';
import {inject} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import {Api} from 'common/server';
import {Page, Notifier} from 'common/ui';
import {Security} from 'common/security';
import {I18n} from 'common/i18n';
import {UiWidgets} from 'services/ui-widgets';
import {CategoryEditor} from './dialog/category-editor';
import {GroupEditor} from './dialog/group-editor';
import {ToolEditor} from './dialog/tool-editor';
import {DialogService} from 'aurelia-dialog';
PLATFORM.moduleName('./dialog/category-editor');
PLATFORM.moduleName('./dialog/group-editor');
PLATFORM.moduleName('./dialog/tool-editor');

@inject(EventAggregator, Api, Page, Notifier, Security, I18n, UiWidgets, DialogService)
export class AllSalesTools {

    showVideoUrl = '';
    intros = [];
    categories = [];
    allCategories = [];

    constructor(eventAggregator, api, page, notifier, security, i18n, uiWidgets, dialogService) {
        this._eventAggregator = eventAggregator;
        this._api = api;
        this._page = page;
        this._notifier = notifier;
        this._security = security;
        this._i18n = i18n;
        this.uiWidgets = uiWidgets;
        this._dialogService = dialogService;

        this.isAdmin = this._security.isAdmin;
    }

    activate(params, routerConfig) {
        var me = this;
        me._page.track('members/sales-tools');
        me._initialize(false);
    }

    attached() {
        var me = this;
        me._initializeHandler = me._eventAggregator.subscribe('lpfn.sales-tools.initialize', function(uiWidget) {
            me._initialize(true);
        });
        me._categoryHandler = me._eventAggregator.subscribe('lpfn.sales-tools.category.initialize', function(uiWidget) {
            me.uiWidgets.addToCache(uiWidget);
            me._loadIntros();
        });
        me._groupHandler = me._eventAggregator.subscribe('lpfn.sales-tools.group.initialize', function(uiWidget) {
            me.uiWidgets.addToCache(uiWidget);
            me._loadIntros();
        });
        me._toolHandler = me._eventAggregator.subscribe('lpfn.sales-tools.tool.initialize', function(uiWidget) {
            me.uiWidgets.addToCache(uiWidget);
            me._loadIntros();
        });
    }

    detached() {
        this._initializeHandler.dispose();
        this._categoryHandler.dispose();
        this._groupHandler.dispose();
        this._toolHandler.dispose();
    }

    _initialize(clear) {
        if (clear) {
            this.intros = [];
        }
        this._loadIntros();
    }

    _loadIntros() {
        var me = this;
        return new Promise((resolve, reject) => {
            if (me.intros.length > 0) { resolve(); return; }

            me.allCategories = [];
            me._api.getData('sales-tools/list/' + me._i18n.currentLocale).then(all => {
                me.intros = all;
                for (let i of all) {
                    me.allCategories.push({ id: i.id, title: i.title });
                }
                resolve();
            }).catch(error => {
                reject();
            });
        });
    }

    openCategoryEditor(category) {
        let ordering = 1;
        if (category || this.intros.length) ordering = category ? category.ordering : this.intros[this.intros.length-1].ordering + 1;
        var model = { 
            ordering: ordering,
            category: category
        };
        this._dialogService.open({ viewModel: CategoryEditor, model: model, ignoreTransitions: true }).whenClosed(response => {
            if (response.wasCancelled) return;
            if (!response.output) {
                this._initialize(true);
                return;
            }
            if (!category || response.output.slug === category.slug) {
                this._initialize(true);
                return;
            }
            this.uiWidgets.initialize().then(() => {
                this._initialize(true);
            });
        });
    }

    openGroupEditor(category, group) {
        var model = {
            categoryId: category.id,
            categories: this.allCategories,
            group: group,
            ordering: group ? group.ordering : category.groups.length ? category.groups[category.groups.length-1].ordering + 1 : 1
        };
        this._dialogService.open({ viewModel: GroupEditor, model: model, ignoreTransitions: true }).whenClosed(response => {
            if (response.wasCancelled) return;
            if (!response.output) {
                this._initialize(true);
                return;
            }
            if (!group || (response.output.slug === group.slug && response.output.categoryId === category.id)) {
                this._initialize(true);
                return;
            }
            this.uiWidgets.initialize().then(() => {
                this._initialize(true);
            });
        });
    }

    openToolEditor(category, group, tool) {
        let categoryGroups = [];
        for (let g of category.groups) categoryGroups.push({ id: g.id, name: g.name });
        var model = {
            groupId: group.id,
            groups: categoryGroups,
            tool: tool,
            ordering: tool ? tool.ordering : group.salesTools.length ? group.salesTools[group.salesTools.length-1].ordering + 1 : 1
        };
        this._dialogService.open({ viewModel: ToolEditor, model: model, ignoreTransitions: true }).whenClosed(response => {
            if (!response.output) {
                this._initialize(true);
                return;
            }
            if (!tool || (response.output.slug === tool.slug && response.output.categoryId === category.id)) {
                this._initialize(true);
                return;
            }
            this.uiWidgets.initialize().then(() => {
                this._initialize(true);
            });
        });
    }
}
