import { bindable } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Api } from 'common/server';
import { Security } from 'common/security';
import { c } from 'common/common';
import { LpfnUtil } from 'common/utils';

export class PolicyCard {
    static inject = [Element, EventAggregator, Api, Security];
    _element;
    @bindable policyId;
    policy = null;
    tab;
    tabView = '';
    tabViewModel = null;
    isAdmin = false;
    showEditLink = false;

    tabs = [
        { key: 'overview', title: 'overview', viewModel: PLATFORM.moduleName('./tabs/tab-policy'), display: true },
        { key: 'notes', title: 'notes', viewModel: PLATFORM.moduleName('./tabs/tab-notes'), display: true },
        { key: 'messages', title: 'messages', viewModel: PLATFORM.moduleName('./tabs/tab-messages'), display: true },
        { key: 'commissions', title: 'profile-commissions', viewModel: PLATFORM.moduleName('./tabs/tab-commissions'), display: true },
        { key: 'to-dos', title: 'to-dos', viewModel: PLATFORM.moduleName('./tabs/tab-to-dos'), display: true },
    ];

    _handlers = [];

	constructor(element, ea, api, security) {
        this._element = element;
		this._ea = ea;
		this.api = api;
		this.security = security;
        if (!this.security.canSeeCommissions()) this.tabs.find(x => x.key === 'commissions').display = false;
	}

    activate(model) {
        this.tab = model.tab ? this.tabs.find(x => x.key === model.tab.key) || this.tabs[0] : this.tabs[0];
        this.policyId = model.id;
    }

    deactivate() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    attached() {
        this._load();
    }

    policyIdChanged() {
        this._load();
    }

    async _load() {
        if (!this.policyId) return;
        try {
    		this.policy = await this.api.get(`production/policy/${encodeURIComponent(this.policyId)}`);
            this._element.dispatchEvent(new CustomEvent('loaded', { bubbles: true, detail: { carrierName: this.policy.carrier.name, policyNumber: this.policy.policyNumber } }));
            this.displayTab(this.tab);
		} catch (err) {
            console.log(err);
        }
	}

    openEmergencyContactForm() {
        const clientFirstName = this.policy.client?.firstName ?? '';
        const clientLastName = this.policy.client?.lastName ?? '';
        const policyNumber = this.policy.policyNumber;
        const formUrl = c.Helpers.replaceVariables(this.security, `https://jotform.com/241417985616162?agentName[first]={{firstName}}&agentName[last]={{lastName}}&agentEmail={{email}}&agentMobile[full]={{cell}}&baseshopName[first]={{agencyFirstName}}&baseshopName[last]={{agencyLastName}}&baseshopEmail={{agencyEmail}}&baseshopMobile[full]={{agencyCell}}&type=Emergency%20Contact&ReferringClientsLegalName[first]=${encodeURIComponent(clientFirstName)}&ReferringClientsLegalName[last]=${encodeURIComponent(clientLastName)}&agentNumber={{agentID}}&leadId=&ReferringClientsPolicyID=${encodeURIComponent(policyNumber)}`, true);
        var parentZindex = LpfnUtil.getHighestZindex(this._element);
        this._ea.publish(c.EventKeys.site.openLightbox, { embedSrc: formUrl, zIndex: parentZindex + 1 });
    }

    displayTab(tab) {
        this.tab = tab || this.tabs[0];
        this.tabModel = this.tabModel || {};
        this.tabViewModel = this.tab.viewModel;
	    switch (this.tab.key) {
	        case 'overview':
	            this.tabModel = this.policy;
	            break;
            case 'notes':
                this.tabModel = { policyId: this.policy.id, security: this.security };
                break;
            case 'messages':
                this.tabModel = { policyId: this.policy.id };
                break;
            case 'commissions':
                this.tabModel = { policyId: this.policy.id };
                break;
            case 'to-dos':
                this.tabModel = { policyId: this.policy.id, security: this.security };
                break;
            default:
                this.tabModel = this.policy;
                break;
        }
	}
}
