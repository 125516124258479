import { PLATFORM } from 'aurelia-pal';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Security } from 'common/security';
import { Menus } from 'services/menus';
import { RouterConfigBase } from 'resources/base-classes/router-config-base';

export class ProductionIndex extends RouterConfigBase {
    static inject = [EventAggregator, Security, Menus];

    constructor(ea, security, menus) {
		super(ea, security, menus, undefined, false);
	}

	configureRouter(config, router){
	    config.map([
            {
				route: 'summary',
				moduleId: PLATFORM.moduleName('members/recruiting/summary'),
				nav: false,
                settings: { title: 'recruiting', description: 'recruiting-description', canAddToMobileMenu: false }
			},
            {
				route: 'onboarding-summary',
				moduleId: PLATFORM.moduleName('members/recruiting/onboarding-summary'),
				nav: false,
                settings: { title: 'onboarding', description: 'onboarding-summary-description', canAddToMobileMenu: false }
			}
	    ]);

		this.router = router;
	}

	attached() {
        this._attached();
    }

    detached() {
        this._detached();
    }
}
