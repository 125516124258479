import { PLATFORM } from 'aurelia-pal';
import { inject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Security } from 'common/security';
import { Notifier, Page } from 'common/ui';
import { Api } from 'common/server';
import { I18n } from 'common/i18n';
import { RapidStarts } from 'services/rapid-starts';
import { RapidStartEditor } from './dialog/rapid-start-editor';
import { RapidStartTopicEditor } from './dialog/rapid-start-topic-editor';
import { DialogService } from 'aurelia-dialog';
import { ROLE } from 'common/constants';
PLATFORM.moduleName('./dialog/rapid-start-editor');
PLATFORM.moduleName('./dialog/rapid-start-topic-editor');

@inject(EventAggregator, Security, Notifier, Page, Api, I18n, RapidStarts, DialogService)
export class RapidStart {

    slug = null;
    rapidStarts = [];

    constructor(eventAggregator, security, notifier, page, api, i18n, rapidStarts, dialogService) {
        this.eventAggregator = eventAggregator;
        this.security = security;
        this.notifier = notifier;
	    this.page = page;
	    this.api = api;
	    this.i18n = i18n;
	    this._rapidStarts = rapidStarts;
	    this.dialogService = dialogService;

        this.isAdmin = this.security.isInRole([ROLE.Admin, ROLE.ResourceAdmin, ROLE.TrainingAdmin]);
    }

    activate(params) {
        this.slug = params.slug || null;
        this.page.track('members/rapid-start');
        this._initialize(true);
    }

    attached() {
	    this._initializeHandler = this.eventAggregator.subscribe('lpfn.rapid-start.initialize', (uiWidget) => {
	        this._initialize(true);
	    });
	}

	detached() {
	    this._initializeHandler.dispose();
	}

	async _initialize(clear) {
		try {
		    const intros = await this._rapidStarts.initialize(clear);
	        this.rapidStarts = intros;

	        for (var i = 0; i < this.rapidStarts.length; i++) {
                if (this.rapidStarts[i].slug !== this.slug) continue;
                break;
            }
	    } catch (err) {
			console.log(err);
		}
	}

	openRapidStartEditor(rapidStart) {
	    this.dialogService.open({ viewModel: RapidStartEditor, model: rapidStart, ordering: rapidStart.ordering, ignoreTransitions: true }).whenClosed(response => {
	        if (response.wasCancelled) return;

	        if (response.output.rapidStartDeleted) {
	            this.slug = null;
	        }
            
	        this.rapidStarts = [];
	        this._initialize(true);
	    });
	}

	openAddRapidStart() {
	    var model = {
	        ordering: this.rapidStarts.length ? this.rapidStarts[this.rapidStarts.length-1].ordering + 1 : 1
	    };
	    this.dialogService.open({ viewModel: RapidStartEditor, model: model, ignoreTransitions: true }).whenClosed(response => {
	        if (response.wasCancelled) return;
	        this.rapidStarts = [];
	        this._initialize(true);
	    });
	}

	openRapidStartTopicEditor(rapidStart, topic) {
	    var model = {
	        rapidStartId: rapidStart.id,
	        topic: topic,
	        ordering: topic ? topic.ordering : rapidStart.topics[rapidStart.topics.length-1].ordering + 1
	    };
	    this.dialogService.open({ viewModel: RapidStartTopicEditor, model: model, ignoreTransitions: true }).whenClosed(response => {
	        if (response.wasCancelled) return;

	        this.rapidStarts = [];
	        this._initialize(true);
	    });
	}

	openAddRapidStartTopic(rapidStart) {
	    var model = { 
	        rapidStartId: rapidStart.id, 
	        topic: null,
	        ordering: rapidStart.topics.length ? rapidStart.topics[rapidStart.topics.length-1].ordering + 1 : 1
	    };
	    this.dialogService.open({ viewModel: RapidStartTopicEditor, model: model, ignoreTransitions: true }).whenClosed(response => {
	        if (response.wasCancelled) return;
	        this.rapidStarts = [];
	        this._initialize(true);
	    });
	}
}
