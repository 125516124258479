import { PLATFORM } from 'aurelia-pal';
import {inject} from 'aurelia-framework';
import {I18n} from 'common/i18n';

@inject(I18n)
export class SalesToolsIndex {
    _i18n;

    constructor(i18n) {
        this._i18n = i18n;
    }

    configureRouter(config, router){
        config.map([
            {
                route: '',
                moduleId: PLATFORM.moduleName('members/sales-tools/all'),
                title: this._i18n.tr('sales-tools-all-title'),
                nav: false,
                settings: { canAddToMobileMenu: false }
            }
        ]);

        this.router = router;
    }
}
