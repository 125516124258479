import {inject} from 'aurelia-framework';
import {I18n} from 'common/i18n';
import {Security} from 'common/security';
import {RecruitingService} from 'services/recruiting-service';
import moment from 'moment';
import {c} from 'common/common';

@inject(I18n, Security, RecruitingService)
export class Summary {
    _forMemberId;

    _i18n;
    _security;
    _recruiting;

    teamOptions;
    teamSummary = false;
    start;
    end;

    columns;
    gridData;
    emptyGridMessage;
    loadingTimestamp;

    directUplines = [];

    constructor(i18n, security, recruitingService) {
        this._i18n = i18n;
        this._security = security;
        this._recruiting = recruitingService;

        this.myAgentId = this._security.authenticatedMemberId;

        this.teamOptions = [];
        this.teamOptions.push({ key: false, name: this._i18n.tr('recruiting-summary-option-personal') });
        this.teamOptions.push({ key: true, name: this._i18n.tr('recruiting-summary-option-team') });

        this._initialize();
    }

    _initialize() {
        this.emptyGridMessage = this._i18n.tr('recruiting-summary-none-found');
        this.columns = [];
        this.columns.push(c.FlexGrid.column('agent', c.FlexGrid.sizes.medium, this._i18n.tr('recruiting-summary-agent'), c.FlexGrid.types.member, null));
        this.columns.push(c.FlexGrid.column('level', c.FlexGrid.sizes.small, this._i18n.tr('recruiting-summary-level'), c.FlexGrid.types.string, null));
        this.columns.push(c.FlexGrid.column('recruits', c.FlexGrid.sizes.small, this._i18n.tr('recruiting-summary-recruits'), c.FlexGrid.types.number, { formatter: 1 }));
    }

    activate(params) {
        this.teamSummary = params.teamSummary || false;
        let start = params.start || null;
        if (start) this.start = moment(start);
        let end = params.end || null;
        if (end) this.end = moment(end);
        this._forMemberId = params.forMemberId || null;
        this.backUrl = params.backUrl || null;
        this.displayBackLink = this.backUrl ? true : false;
        this.backText = params.backText || '';

        this._loadSummary();
    }

    _loadSummary() {
        this.loadingTimestamp = moment().unix();

        const start = this.start ? this.start.format('MM/DD/YYYY') : null;
        const end = this.end ? this.end.format('MM/DD/YYYY') : null;
        this._recruiting.getSummaries(this._forMemberId, this.teamSummary, start, end).then(data => {
            let gridData = [];
            for (let record of data) {
                let directUplineExists = this.directUplines.find(x => x.id === record.directUplineId);
                if (!directUplineExists) this.directUplines.push({ id: record.directUplineId, name: record.directUplineName });
                gridData.push({
                    highlight: false,
                    values: [
                        c.FlexGrid.cellData('id', record.memberId),
                        c.FlexGrid.cellData('agent', { id: record.id, fullName: record.agentName }),
                        c.FlexGrid.cellData('level', record.level),
                        c.FlexGrid.cellData('recruits', record.recruits),
                        c.FlexGrid.cellData('upline', record.directUplineName)
                    ]
                });
            }
            this.gridData = gridData;
        }).catch(err => {
            this.gridData = [];
        });
    }

    search() {
        this._loadSummary();
    }

    clearDrilldown() {
        this._forMemberId = this.directUplines[0].id;
        this.directUplines = [];
        this._loadSummary();
    }

    backToDirectUpline(memberId) {
        if (this._forMemberId === memberId) return;

        let index = this.directUplines.findIndex(x => x.id === memberId);
        if (index + 1 <= this.directUplines.length) {
            this.directUplines.splice(index + 1);
        }
        this.showClickthrough(memberId, null);
    }

    showClickthrough(memberId, gridData) {
        this._forMemberId = memberId;
        if (gridData) {
            let nameValue = gridData.values.find(x => x.key === 'agent');
            this.directUplines.push({ id: memberId, name: nameValue.value });
        }
        this._loadSummary();
    }
}
