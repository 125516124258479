import {inject} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {NewInstance} from 'aurelia-dependency-injection';
import {ValidationRules, ValidationController} from 'aurelia-validation';
import {BootstrapFormValidationRenderer} from 'validation/bootstrap-form-validation-renderer';
import {I18n} from 'common/i18n';
import {Notifier} from 'common/ui';
import {SalesTools} from 'services/sales-tools';
import {Security} from 'common/security';
import {c} from 'common/common';
import Dropzone from 'dropzone';
import environment from '../../../../config/environment.json';

@inject(DialogController, NewInstance.of(ValidationController), I18n, Notifier, SalesTools, Security)
export class ToolEditor {

    id = null;
    groupId = null;
    newGroupId;
    slug = '';
    ordering = null;
    isActive = false;
    showDeleteConfirm = false;

    showUpload = false;
    formAction = '';
    fileDropzone = null;
    displayClearButton;

    constructor(dialogController, validationController, i18n, notifier, salesTools, security) {
        this.dialogController = dialogController;
        this.dialogController.settings.centerHorizontalOnly = true;
        this.validationController = validationController;
        this.validationController.addRenderer(new BootstrapFormValidationRenderer());
        this.i18n = i18n;
        this.notifier = notifier;
        this.salesTools = salesTools;
        this.security = security;

        this.deleteText = this.i18n.tr('delete');

        ValidationRules
            .ensure('slug').required().matches(c.RegEx.slug)
            .ensure('ordering').required().satisfiesRule('integerRange', 1, 5000)
            .on(this);
    }

    activate(model) {
        this.id = null;
        this.groupId = model.groupId;
        this.newGroupId = model.groupId;
        this.groups = model.groups;
        this.slug = '';
        this.ordering = model.ordering || 1;
        this.isActive = false;
        this.title = this.i18n.tr('sales-tools-tool.add-title');
        this.formAction = '';
        if (model.tool) {
            this.showUpload = true;
            this.title = this.i18n.tr('sales-tools-tool.edit-title');
            this.id = model.tool.id;
            this.slug = model.tool.slug;
            this.ordering = model.tool.ordering;
            this.isActive = model.tool.isActive;
            this.formAction = `${environment.api}/api/sales-tools/tool/${this.id}`;
        }

        if (this.showUpload && this.fileDropzone !== null) this.fileDropzone.options.url = this.formAction;

        this.validationController.reset();
    }

    save() {
        this.validationController.validate().then(v => {
            if (!v.valid) return;

            this.salesTools.saveTool(this.id, this.groupId, this.newGroupId, this.slug, this.ordering, this.isActive).then(newToolId => {
                if (!this.showUpload) {
                    this.id = newToolId;
                    this.formAction = `${environment.api}/api/sales-tools/tool/${this.id}`;
                    this.showUpload = true;
                    this._initializeDropzone();
                } else {
                    this.dialogController.ok({ toolDeleted: false });
                }
            }).catch(error => { });
        });
    }

    resetDelete() {
        this.showDeleteConfirm = false;
        this.deleteText = this.i18n.tr('delete');
    }

    deleteTool() {
        if (!this.showDeleteConfirm) {
            this.showDeleteConfirm = true;
            this.deleteText = this.i18n.tr('delete-confirm');
            return;
        }

        this.showDeleteConfirm = false;

        this.salesTools.deleteTool(this.id).then(() => {
            this.dialogController.ok({ toolDeleted: true });
        }).catch(error => { });
    }

    attached() {
        this._initializeDropzone();
    }

    _initializeDropzone() {
        if (!this.showUpload) return;
        var me = this;
        var d = new Date();
        var timezoneOffset = d.getTimezoneOffset();

        var myDropzone = new Dropzone('#sales-tool-upload-dropzone', {
            dictDefaultMessage: 'Drop the sales tool file here or click the box to select the file',
            url: this.formAction,
            paramName: 'file',
            maxFilesize: 15, // MB
            headers: { 'Authorization': 'Bearer ' + me.security.token, 'X-LEGACY-TimezoneOffset': timezoneOffset },
            acceptedFiles: 'application/pdf,application/zip,application/x-zip,application/x-zip-compressed,application/x-compressed,application/octet-stream,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            maxFiles: 1,
            init: function () {
                this.on('addedfile', function (file) {
                    //console.log("added file.");
                });
                this.on('success', function (file, response) {
                    this.removeFile(file);
                    me.notifier.successText('Sales tool file imported');
                });
                this.on('error', function (file, error, x) {
                    me.displayClearButton = true;
                    me.notifier.errorText('Error importing the sales tool file.<br/>' + error.message);
                });
            }
        });

        me.fileDropzone = myDropzone;
    }

    clearZone() {
        this.fileDropzone.removeAllFiles();
        this.displayClearButton = false;
    }
}
