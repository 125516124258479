import { WIDGET, COMMISSION } from 'common/constants';

export class TabCommissions {
    constructor() {}

    activate(model) {
        this.config = {
            memberId: model.memberId,
            policyId: model.policyId,
            groupBy: COMMISSION.GroupBy.Policy,
            widgetPeriod: WIDGET.Period.All,
        };
    }
}
