import {inject} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {NewInstance} from 'aurelia-dependency-injection';
import {ValidationRules, ValidationController} from 'aurelia-validation';
import {BootstrapFormValidationRenderer} from 'validation/bootstrap-form-validation-renderer';
import {I18n} from 'common/i18n';
import {Notifier} from 'common/ui';
import {SalesTools} from 'services/sales-tools';
import {c} from 'common/common';

@inject(DialogController, NewInstance.of(ValidationController), I18n, Notifier, SalesTools)
export class CategoryEditor {

    id = null;
    slug = '';
    ordering = null;
    isActive = false;
    showDeleteConfirm = false;
    levels = [];

    constructor(dialogController, validationController, i18n, notifier, salesTools) {
        this.dialogController = dialogController;
        this.dialogController.settings.centerHorizontalOnly = true;
        this.validationController = validationController;
        this.validationController.addRenderer(new BootstrapFormValidationRenderer());
        this.i18n = i18n;
        this.notifier = notifier;
        this.salesTools = salesTools;

        this.deleteText = this.i18n.tr('delete');

        ValidationRules
            .ensure('slug').required().matches(c.RegEx.slug)
            .ensure('ordering').required().satisfiesRule('integerRange', 1, 5000)
            .on(this);
    }

    activate(model) {
        this.id = null;
        this.slug = '';
        this.ordering = model.ordering || 1;
        this.isActive = false;
        this.title = this.i18n.tr('sales-tools-category-add-title');
        if (model.category) {
            this.title = this.i18n.tr('sales-tools-category-edit-title');
            this.id = model.category.id;
            this.slug = model.category.slug;
            this.ordering = model.category.ordering;
            this.isActive = model.category.isActive;
        }

        this.validationController.reset();
    }

    save() {
        this.validationController.validate().then(v => {
            if (!v.valid) return;

            this.salesTools.saveCategory(this.id, this.slug, this.ordering, this.isActive).then(() => {
                this.dialogController.ok({ categoryDeleted: false });
            }).catch(error => { });
        });
    }

    resetDelete() {
        this.showDeleteConfirm = false;
        this.deleteText = this.i18n.tr('delete');
    }

    deleteCategory() {
        if (!this.showDeleteConfirm) {
            this.showDeleteConfirm = true;
            this.deleteText = this.i18n.tr('delete-confirm');
            return;
        }

        this.showDeleteConfirm = false;

        this.salesTools.deleteCategory(this.id).then(() => {
            this.dialogController.ok({ categoryDeleted: true });
        }).catch(error => { });
    }
}
