import {inject} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {NewInstance} from 'aurelia-dependency-injection';
import {ValidationRules, ValidationController} from 'aurelia-validation';
import {BootstrapFormValidationRenderer} from 'validation/bootstrap-form-validation-renderer';
import {I18n} from 'common/i18n';
import {Notifier} from 'common/ui';
import {RapidStarts} from 'services/rapid-starts';
import {Api} from 'common/server';
import {c} from 'common/common';

@inject(DialogController, NewInstance.of(ValidationController), I18n, Notifier, RapidStarts, Api)
export class RapidStartEditor {

    id = null;
    slug = '';
    ordering = null;
    isActive = false;
    showDeleteConfirm = false;
    canEditSlug = false;
    minimumLevel = null;
    levels = [];

    constructor(dialogController, validationController, i18n, notifier, rapidStarts, api) {
        this.dialogController = dialogController;
        this.dialogController.settings.centerHorizontalOnly = true;
        this.validationController = validationController;
        this.validationController.addRenderer(new BootstrapFormValidationRenderer());
        this.i18n = i18n;
        this.notifier = notifier;
        this.rapidStarts = rapidStarts;
        this._api = api;

        this.deleteText = this.i18n.tr('delete');

        ValidationRules
            .ensure('slug').required().matches(c.RegEx.slug)
            .ensure('ordering').required().satisfiesRule('integerRange', 1, 5000)
            .on(this);
    }

    async activate(model) {
        await this._loadLevels();
        this.id = null;
        this.slug = '';
        this.ordering = model.ordering || 1;
        this.isActive = false;
        this.title = this.i18n.tr('rapid-start-add-title');
        this.minimumLevel = null;
        if (model) {
            this.title = this.i18n.tr('rapid-start-edit-title');
            this.id = model.id;
            this.slug = model.slug;
            this.ordering = model.ordering;
            this.isActive = model.isActive;
            this.minimumLevel = model.minimumLevel;
        }

        this.canEditSlug = !this.id;
        this.validationController.reset();
    }

    save() {
        var me = this;
        this.validationController.validate().then(v => {
            if (!v.valid) return;
            if (me.minimumLevel === 'null') me.minimumLevel = null;

            me.rapidStarts.saveRapidStart(me.id, me.slug, me.ordering, me.isActive, me.minimumLevel).then(() => {
                me.dialogController.ok({ rapidStartDeleted: false });
            }).catch(error => { });
        });
    }

    resetDelete() {
        this.showDeleteConfirm = false;
        this.deleteText = this.i18n.tr('delete');
    }

    deleteRapidStart() {
        if (!this.showDeleteConfirm) {
            this.showDeleteConfirm = true;
            this.deleteText = this.i18n.tr('delete-confirm');
            return;
        }

        this.showDeleteConfirm = false;
        var me = this;

        me.rapidStarts.deleteRapidStart(me.id).then(() => {
            me.dialogController.ok({ rapidStartDeleted: true });
        }).catch(error => { });
    }

    async _loadLevels() {
        try {
            this.levels = await this._api.get('level/list-all');
        } catch (err) {
            console.log(err);
        }
    }
}
