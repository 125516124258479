import {inject} from 'aurelia-framework';
import {I18n} from 'common/i18n';
import {Security} from 'common/security';
import {MemberService} from 'services/member-service';
import {RecruitingService} from 'services/recruiting-service';
import moment from 'moment';
import {c} from 'common/common';

@inject(I18n, Security, MemberService, RecruitingService)
export class OnboardingSummary {
    _forMemberId;

    _i18n;
    _security;
    _memberSvc;
    _recruiting;

    teamOptions;
    teamSummary = false;
    start;
    end;

    columns;
    gridData;
    emptyGridMessage;
    loadingTimestamp;

    drilldowns = [];

    constructor(i18n, security, memberService, recruitingService) {
        this._i18n = i18n;
        this._security = security;
        this._memberSvc = memberService;
        this._recruiting = recruitingService;

        this.myAgentId = this._security.authenticatedMemberId;

        this.teamOptions = [];
        this.teamOptions.push({ key: false, name: this._i18n.tr('recruiting-onboarding-option-personal') });
        this.teamOptions.push({ key: true, name: this._i18n.tr('recruiting-onboarding-option-team') });

        this._initialize();
    }

    _initialize() {
        this.emptyGridMessage = this._i18n.tr('recruiting-onboarding-none-found');
        this.columns = [];
        this.columns.push(c.FlexGrid.column('agent', c.FlexGrid.sizes.medium, this._i18n.tr('recruiting-onboarding-agent'), c.FlexGrid.types.member, null));
        this.columns.push(c.FlexGrid.column('registered', c.FlexGrid.sizes.small, this._i18n.tr('recruiting-onboarding-registration-date-abbreviated'), c.FlexGrid.types.date, { formatter: 'l' }));
        this.columns.push(c.FlexGrid.column('level', c.FlexGrid.sizes.small, this._i18n.tr('recruiting-onboarding-level'), c.FlexGrid.types.string, null));
        this.columns.push(c.FlexGrid.column('licensed', c.FlexGrid.sizes.small, this._i18n.tr('recruiting-onboarding-licensed'), c.FlexGrid.types.bool, null));
    }

    activate(params) {
        this.teamSummary = params.teamSummary || false;
        let start = params.start || null;
        if (start) this.start = moment(start);
        let end = params.end || null;
        if (end) this.end = moment(end);
        this._forMemberId = params.forMemberId || null;
        this.backUrl = params.backUrl || null;
        this.displayBackLink = this.backUrl ? true : false;
        this.backText = params.backText || '';

        this._loadSummary();

        this._memberSvc.getProfile(this._forMemberId).then(profile => {
            this.drilldowns.push({ id: profile.id, name: profile.fullName });
        });
    }

    _loadSummary(fromDrilldown) {
        this.loadingTimestamp = moment().unix();

        const start = this.start ? this.start.format('MM/DD/YYYY') : null;
        const end = this.end ? this.end.format('MM/DD/YYYY') : null;
        this._recruiting.getOnboardingSummaries(this._forMemberId, this.teamSummary, start, end).then(data => {
            let gridData = [];
            for (let record of data) {
                gridData.push({
                    highlight: false,
                    values: [
                        c.FlexGrid.cellData('id', record.memberId),
                        c.FlexGrid.cellData('agent', { id: record.memberId, fullName: record.name }),
                        c.FlexGrid.cellData('registered', record.registrationDate),
                        c.FlexGrid.cellData('level', record.level),
                        c.FlexGrid.cellData('licensed', record.isLicensedLife),
                    ]
                });
            }
            this.gridData = gridData;
        }).catch(err => {
            this.gridData = [];
        });
    }

    search() {
        this._loadSummary();
    }

    clearDrilldown() {
        this._forMemberId = this.drilldowns[0].id;
        this.drilldowns = [this.drilldowns[0]];
        this._loadSummary();
    }

    backInDrilldown(memberId) {
        if (this._forMemberId === memberId) return;

        let index = this.drilldowns.findIndex(x => x.id === memberId);
        this.drilldowns.splice(index + 1, this.drilldowns.length - index + 1);
        this.showDrilldown(memberId, null);
    }

    showDrilldown(memberId, gridData) {
        this._forMemberId = memberId;
        if (gridData) {
            let nameValue = gridData.values.find(x => x.key === 'agent');
            this.drilldowns.push({ id: memberId, name: nameValue.value });
        }
        this._loadSummary();
    }
}
