import { inject } from 'aurelia-framework';
import { activationStrategy } from 'aurelia-router';
import { EventAggregator} from 'aurelia-event-aggregator';
import { Security } from 'common/security';
import { Page } from 'common/ui';
import { Api } from 'common/server';
import { I18n } from 'common/i18n';
import { RapidStarts } from 'services/rapid-starts';
import { ROLE } from 'common/constants';

@inject(EventAggregator, Security, Page, Api, I18n, RapidStarts)
export class RapidStart {

    rapidStartSlug = null;
    slug = null;
    rapidStart = null;
    topic = null;

    constructor(eventAggregator, security, page, api, i18n, rapidStarts) {
        this.eventAggregator = eventAggregator;
        this.security = security;
        this.page = page;
        this.api = api;
        this.i18n = i18n;
        this.rapidStarts = rapidStarts;

        this.isAdmin = this.security.isInRole([ROLE.Admin, ROLE.ResourceAdmin]);
    }

    determineActivationStrategy() {
        return activationStrategy.invokeLifecycle;
    }

    activate(params) {
        this.rapidStartSlug = params.rapidStartSlug || null;
        this.slug = params.slug || null;
        this.page.track('members/rapid-start/' + this.rapidStartSlug + '/' + this.slug);
        this._initialize();
    }

    async _initialize() {
        try {
            this.rapidStart = await this.rapidStarts.getRapidStartIntro(this.rapidStartSlug);

            for (var i = 0; i < this.rapidStart.topics.length; i++) {
                if (this.rapidStart.topics[i].slug !== this.slug) continue;
                this.topic = this.rapidStart.topics[i];
                break;
            }

            if (!this.topic) {
                this.topic = this.rapidStart.topics[0];
            }
        } catch (err) {
            console.log(err);
        }
    }
}
